import 'core-js/stable';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'regenerator-runtime/runtime';

/* eslint-disable no-restricted-imports */

const main = async () => {
  import('./import/bootstrap_');
  import('./import/intersectionObserver');
  import('./import/smoothscroll');
  import('./import/fontloaderDef');
  import('./import/bootstrap-tooltip');

  if (window.location.pathname === '/' ||
      window.location.pathname === '/index.php') {
    import('./import/bootstrap-carousel');
  }

  if (window.location.pathname.includes('portfolio') ||
    window.location.pathname.includes('upproject') ||
    window.location.search.includes('mact=UpProject')) {
    import('./import/lightgallery');
  }

  if (window.location.pathname.includes('news') ||
      window.location.search.includes('mact=News')) {
  }

  if (window.location.pathname.includes('contact')) {
    await import('./import/vue-leaflet.js');
    await import('./import/vue-contactform.js');
  }
};

/* eslint-enable no-restricted-imports */

main();

//
// EOF
//
